import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Button: React.FC<{
  onClick?: () => void;
  children: React.ReactNode;
  type?: 'submit' | 'reset' | 'button' | undefined;
  disabled?: boolean;
  variant?: 'primary' | 'success' | undefined;
  isLoading?: boolean;
}> = ({ onClick, children, type, disabled, variant = 'primary', isLoading }) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        'px-3 py-2 text-white rounded transition-colors font-semibold disabled:!bg-gray-400',
        {
          'bg-cyan-900 hover:bg-cyan-800': variant === 'primary',
          'bg-green-600 hover:bg-green-500': variant === 'success',
          '!bg-blue-400': isLoading
        }
      )}
      type={type}
      disabled={disabled || isLoading}>
      {isLoading ? <FontAwesomeIcon icon={faSpinner} spin={true} className="mr-2" /> : null}
      {children}
    </button>
  );
};

export default Button;
