import CodeReader from '../../../components/codeReader';
import useInitialization from '../../../hooks/useInitialization';

const QrCode = () => {
  const { isLoading, doInitialization } = useInitialization();

  const handleScan = async (data: any) => {
    try {
      const jsonData = JSON.parse(data);
      if (jsonData && jsonData.handshake_version === 1) {
        await doInitialization(jsonData.url, jsonData.token);
      } else {
        alert('Der QR-Code ist ungültig.');
      }
    } catch (e) {
      alert('Leider ist ein Fehler aufgetreten: ' + e);
    }
  };

  return (
    <div>
      <div>Bitte scanne den QR-Code aus Pretix, um fortzufahren.</div>
      <CodeReader onScan={handleScan} />
      {isLoading ? <div>Initialisiere...</div> : null}
    </div>
  );
};

export default QrCode;
