import CodeReader from '../codeReader';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { useLazyListPositionsQuery } from '../../redux/api/checkinListApi';

const OrderLoader = () => {
  const authState = useSelector((state: RootState) => state.auth);
  const settingsState = useSelector((state: RootState) => state.settings);

  const [doSearchPositions] = useLazyListPositionsQuery();
  const navigate = useNavigate();

  const handleScan = async (data: any) => {
    if (data) {
      const result = await doSearchPositions({
        organizer: authState.organizer,
        event: settingsState.eventSlug,
        listId: settingsState.checkinListId,
        search: data
      });
      if (result.data?.count === 1) {
        navigate('/order/' + result.data.results[0].secret);
      } else {
        alert('Ticket wurde nicht gefunden.');
      }
    }
  };

  return <CodeReader onScan={handleScan} />;
};

export default OrderLoader;
