import React, { FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useGetOrderQuery, useUpdateOrderMutation } from '../../../redux/api/orderApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { format } from 'date-fns';
import Button from '../../../components/inputs/Button';

const AddComment: React.FC<{ code: string }> = ({ code }) => {
  const authState = useSelector((state: RootState) => state.auth);
  const settingsState = useSelector((state: RootState) => state.settings);
  const { data: order } = useGetOrderQuery(
    authState.organizer && settingsState.eventSlug && code
      ? { organizer: authState.organizer, event: settingsState.eventSlug, code }
      : skipToken
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [text, setText] = useState('');

  const [doUpdateOrder, { isLoading }] = useUpdateOrderMutation();

  const handleSave = (e: FormEvent) => {
    e.preventDefault();
    if (text?.length < 1) return;
    doUpdateOrder({
      organizer: authState.organizer,
      event: settingsState.eventSlug,
      code,
      data: {
        comment: `${order.comment ?? ''}\n\n${format(new Date(), 'dd.MM.yy HH:mm')} - ${authState.name}:\n${text}`
      }
    }).then(() => {
      setText('');
      setIsOpen(false);
    });
  };

  return isOpen ? (
    <form onSubmit={handleSave}>
      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="w-full border border-gray-400 rounded p-2"
        rows={3}
      />
      <Button type="submit" isLoading={isLoading} disabled={text?.length < 1}>
        Speichern
      </Button>
    </form>
  ) : (
    <button
      onClick={() => setIsOpen(true)}
      className="text-sm text-blue-900 hover:text-blue-600 transition-colors">
      Kommentar hinzufügen
    </button>
  );
};

export default AddComment;
