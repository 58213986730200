import { Link } from 'react-router-dom';
import ShowSettings from './ShowSettings';

const Header = () => {
  return (
    <header className="px-12 py-6 bg-gray-200 shadow-md sticky top-0 flex items-center">
      <Link to="/" className="text-2xl font-bold uppercase tracking-wider">
        Kuko Check-In
      </Link>
      <Link
        to="/"
        className="ml-12 mt-1 font-bold hover:text-blue-600 transition-colors uppercase tracking-wider">
        Suche
      </Link>
      <div className="ml-auto">
        <ShowSettings />
      </div>
    </header>
  );
};

export default Header;
