import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  eventSlug: null,
  eventName: null,
  checkinListId: null,
  checkinListName: null
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setEvent(state, action) {
      state.eventSlug = action.payload.slug;
      state.eventName = action.payload.name;
    },
    setCheckinList(state, action) {
      state.checkinListId = action.payload.id;
      state.checkinListName = action.payload.name;
    },
    clearSettings() {
      return initialState;
    }
  }
});

export const { setEvent, setCheckinList, clearSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
