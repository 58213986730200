import React from 'react';

const TextInput: React.FC<{
  value?: string;
  onChange: (e: any) => void;
  placeholder?: string;
  required?: boolean;
}> = ({ value, onChange, placeholder, required }) => {
  return (
    <div className="mt-2 mb-3">
      <input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        className="block w-full px-3 py-2 border border-gray-400 rounded hover:border-gray-500 focus:border-gray-800 outline-none"
      />
    </div>
  );
};

export default TextInput;
