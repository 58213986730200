import React from 'react';
import classNames from 'classnames';

const StatusHeader: React.FC<{ data: any }> = ({ data }) => (
  <div
    className={classNames(
      'h-[120px] flex items-center justify-center uppercase text-3xl tracking-widest font-semibold',
      data.bg,
      data.text
    )}>
    Ticket {data.title}
  </div>
);

export default StatusHeader;
