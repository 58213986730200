import { pretixApi } from './index';

const orderApi = pretixApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrder: builder.query<any, { organizer: string; event: string; code: string }>({
      query: ({ organizer, event, code }) => ({
        url: `/api/v1/organizers/${organizer}/events/${event}/orders/${code}/`,
        method: 'GET'
      }),
      providesTags: ['Order']
    }),
    updateOrder: builder.mutation<
      any,
      { organizer: string; event: string; code: string; data: any }
    >({
      query: ({ organizer, event, code, data }) => ({
        url: `/api/v1/organizers/${organizer}/events/${event}/orders/${code}/`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['Order']
    }),
    updateOrderPosition: builder.mutation<
      any,
      { organizer: string; event: string; positionId: string; data: any }
    >({
      query: ({ organizer, event, positionId, data }) => ({
        url: `/api/v1/organizers/${organizer}/events/${event}/orderpositions_kuko/${positionId}/`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['Positions']
    })
  })
});

export const { useGetOrderQuery, useUpdateOrderMutation, useUpdateOrderPositionMutation } =
  orderApi;
