import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

const dynamicBaseQuery = (args: any, api: any, extraOptions: any) => {
  const state = api.getState() as RootState;
  const authState = state.auth;

  const baseUrl = authState.url || '';

  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      if (authState.apiToken) {
        headers.set('Authorization', `Device ${authState.apiToken}`);
      }
      return headers;
    }
  })(args, api, extraOptions);
};

export const pretixApi = createApi({
  reducerPath: 'pretixApi',
  tagTypes: ['Items', 'Order', 'Positions', 'Questions'],
  endpoints: () => ({}),
  baseQuery: dynamicBaseQuery
});
