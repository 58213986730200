import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { skipToken } from '@reduxjs/toolkit/query';
import useOrderStatusInformation from '../../hooks/useOrderStatusInformation';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import usePositionModel from '../../hooks/usePositionModel';
import KukoData from './KukoData';
import { useListPositionsQuery, useRedeemMutation } from '../../redux/api/checkinListApi';
import Button from '../../components/inputs/Button';
import Steward from './Steward';
import { useUpdateOrderPositionMutation } from '../../redux/api/orderApi';
import StatusHeader from './StatusHeader';
import Comments from './Comments';
import Answers from './Answers';
import InvoiceAddress from './InvoiceAddress';

const Order = () => {
  const authState = useSelector((state: RootState) => state.auth);
  const settingsState = useSelector((state: RootState) => state.settings);
  const { secret } = useParams();
  const { data: positions } = useListPositionsQuery(
    authState.organizer && settingsState.eventSlug && settingsState.checkinListId && secret
      ? {
          organizer: authState.organizer,
          event: settingsState.eventSlug,
          listId: settingsState.checkinListId,
          search: secret
        }
      : skipToken
  );
  const [doRedeem] = useRedeemMutation();
  const [doUpdateOrderPosition] = useUpdateOrderPositionMutation();
  const getOrderStatusInformation = useOrderStatusInformation();
  const getOrderPositionModel = usePositionModel();
  const [position, setPosition] = useState<any>();
  const [orderStatusInformation, setOrderStatusInformation] = useState<any>({});
  const [orderPosition, setOrderPosition] = useState<any>();
  const [kukoInfo, setKukoInfo] = useState<any>();
  const [positionsForCheckIn, setPositionsForCheckIn] = useState<number[]>([]);
  const [isCheckingIn, setIsCheckingIn] = useState<boolean>(false);
  const [stewardHadVestBefore, setStewardHadVestBefore] = useState<boolean>(false);
  const [stewardData, setStewardData] = useState<any>({ gotVest: false, vestNumber: '' });

  useEffect(() => {
    if (positions?.results?.length === 1) {
      const position = positions.results[0];
      setPosition(position);
      setOrderStatusInformation(getOrderStatusInformation(position));
      const _orderPositionModel = getOrderPositionModel(position);
      setOrderPosition(_orderPositionModel);
      const _positionsForCheckIn = [];
      if (
        _orderPositionModel.mainPosition.checkinStatus === 'ok' &&
        _orderPositionModel.mainPosition.availableForCheckIn
      )
        _positionsForCheckIn.push(_orderPositionModel.mainPosition.id);
      _orderPositionModel.addons?.forEach((addon: any) => {
        if (addon.checkinStatus === 'ok' && addon.availableForCheckIn)
          _positionsForCheckIn.push(addon.id);
      });
      setPositionsForCheckIn(_positionsForCheckIn);
      const kukoInfoData = position?.answers?.find(
        (a: any) => a.question_identifier === 'kuko_info'
      )?.answer;
      if (kukoInfoData) {
        try {
          const _kukoInfo = JSON.parse(kukoInfoData);
          setKukoInfo(_kukoInfo);
          setStewardData(_kukoInfo?.steward);
          if (_kukoInfo?.steward?.gotVest) {
            setStewardHadVestBefore(true);
          } else {
            setStewardHadVestBefore(false);
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        setKukoInfo(undefined);
        setStewardData({ gotVest: false, vestNumber: '' });
        setStewardHadVestBefore(false);
      }
    } else {
      setPosition(undefined);
      setOrderStatusInformation({});
      setOrderPosition(undefined);
      setKukoInfo(undefined);
      setPositionsForCheckIn([]);
      setStewardHadVestBefore(false);
      setStewardData({ gotVest: false, vestNumber: '' });
    }
  }, [positions]);

  const handleTogglePositionCheckIn = (id: number) => {
    if (positionsForCheckIn.includes(id)) {
      setPositionsForCheckIn(positionsForCheckIn.filter((i) => i !== id));
    } else {
      setPositionsForCheckIn([...positionsForCheckIn, id]);
    }
  };

  const handleChangeSteward = ({
    gotVest,
    vestNumber
  }: {
    gotVest: boolean;
    vestNumber: string;
  }) => {
    if (!stewardHadVestBefore) {
      setStewardData({
        ...kukoInfo.steward,
        gotVest,
        vestNumber: gotVest ? vestNumber : ''
      });
    }
  };

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        event.preventDefault();
        if (
          !(
            (positionsForCheckIn?.length < 1 &&
              (!stewardData?.planned || stewardHadVestBefore || !stewardData?.gotVest)) ||
            (stewardData?.planned &&
              !stewardHadVestBefore &&
              stewardData?.gotVest &&
              (!stewardData?.vestNumber || stewardData?.vestNumber?.length < 1))
          ) &&
          !isCheckingIn
        ) {
          handleDoCheckIn().then();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [positionsForCheckIn, stewardData, stewardHadVestBefore, isCheckingIn]);

  const handleDoCheckIn = async () => {
    setIsCheckingIn(true);
    if (stewardData?.planned && !stewardHadVestBefore && stewardData?.gotVest) {
      try {
        await doUpdateOrderPosition({
          organizer: authState.organizer,
          event: settingsState.eventSlug,
          positionId: orderPosition.mainPosition.id,
          data: {
            answers: [
              {
                question: position.answers?.find((a: any) => a.question_identifier === 'kuko_info')
                  ?.question,
                answer: JSON.stringify({
                  ...kukoInfo,
                  steward: {
                    ...kukoInfo?.steward,
                    gotVest: stewardData.gotVest,
                    vestNumber: stewardData.vestNumber
                  }
                })
              }
            ]
          }
        });
      } catch (e) {
        console.log('Ordner:innen-Weste-Checkin fehlgeschlagen', e);
      }
    }
    for (const positionId of positionsForCheckIn) {
      try {
        const result = await doRedeem({
          organizer: authState.organizer,
          event: settingsState.eventSlug,
          listId: settingsState.checkinListId,
          positionId
        }).unwrap();
        switch (result.status) {
          case 'ok':
            break;
          default:
            alert(`Checkin fehlgeschlagen (${positionId}): ${result.status} - ${result.reason}`);
            break;
        }
      } catch (error: any) {
        alert('Leider ist der Checkin fehlgeschlagen: ' + error?.toString());
      }
    }
    setIsCheckingIn(false);
  };

  return position && orderPosition ? (
    <div>
      {orderStatusInformation ? <StatusHeader data={orderStatusInformation} /> : null}
      <div className="px-12 py-8">
        <div className="grid grid-cols-3 gap-4 max-w-[1200px] mx-auto">
          <div className="col-span-2 flex flex-col gap-y-4">
            <div className={classNames('rounded shadow p-4 bg-blue-100')}>
              <div className="flex items-center w-full">
                <h2 className="text-2xl font-bold tracking-wider">{position?.attendee_name}</h2>
                <h3 className="text-md ml-auto">
                  {position?.attendee_email} - {position?.order}
                </h3>
              </div>
              <Answers position={position} />
              <InvoiceAddress code={position?.order} />
            </div>
            {kukoInfo ? <KukoData kukoData={kukoInfo} /> : null}
            <div className={classNames('rounded shadow p-4 flex flex-col gap-y-2 bg-gray-200')}>
              <div className="flex items-start">
                <div className="w-6 h-5 mr-2 mt-[2px]">
                  {orderPosition.mainPosition.availableForCheckIn ? (
                    <input
                      type="checkbox"
                      className="h-5 w-5"
                      disabled={orderPosition.mainPosition.checkinStatus !== 'ok'}
                      checked={
                        positionsForCheckIn.includes(orderPosition.mainPosition.id) ||
                        orderPosition.mainPosition.checkedIn
                      }
                      onChange={() => handleTogglePositionCheckIn(orderPosition.mainPosition.id)}
                    />
                  ) : null}
                </div>
                <div>
                  <div className="font-bold uppercase tracking-widest">
                    {orderPosition.mainPosition.item?.name}
                  </div>
                  {orderPosition.mainPosition.item?.checkInText ? (
                    <div className="text-orange-500 font-semibold">
                      {orderPosition.mainPosition.item?.checkInText}
                    </div>
                  ) : null}
                </div>
              </div>
              {orderPosition.mainPosition.availableForCheckIn &&
              orderPosition.mainPosition.checkinStatusText ? (
                <div className="text-sm text-gray-500">
                  {orderPosition.mainPosition.checkinStatusText}
                </div>
              ) : null}
            </div>
            {kukoInfo?.steward?.planned ? (
              <Steward
                onChange={handleChangeSteward}
                disabled={stewardHadVestBefore}
                gotVest={stewardData.gotVest}
                vestNumber={stewardData.vestNumber}
              />
            ) : null}
            {orderPosition.addons
              ?.sort((a: any, b: any) => a.positionId - b.positionId)
              ?.map((addon: any, j: number) => (
                <div
                  key={j}
                  className={classNames('rounded shadow p-4 flex flex-col gap-y-2 bg-gray-100')}>
                  <div className="flex items-center">
                    {addon.availableForCheckIn ? (
                      <div className="w-6 h-5 mr-2">
                        <input
                          type="checkbox"
                          className="h-5 w-5"
                          disabled={addon.checkinStatus !== 'ok'}
                          checked={positionsForCheckIn.includes(addon.id) || addon.checkedIn}
                          onChange={() => handleTogglePositionCheckIn(addon.id)}
                        />
                      </div>
                    ) : null}
                    <div>
                      <div className="font-bold uppercase tracking-widest">
                        + {addon.item?.name}
                      </div>
                      {addon.item?.checkInText ? (
                        <div className="text-orange-500 font-semibold">
                          {addon.item?.checkInText}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {addon.availableForCheckIn && addon.checkinStatusText ? (
                    <div className="text-sm text-gray-500">{addon.checkinStatusText}</div>
                  ) : null}
                </div>
              ))}
          </div>
          <div className="col-span-1">
            <div className="rounded shadow p-4 bg-gray-200">
              {position?.order__status === 'p' ||
              (position?.order__status === 'n' && position.ticketbourse_is_paid) ? (
                (orderPosition.mainPosition.checkinStatus === 'ok' &&
                  orderPosition.mainPosition.availableForCheckIn) ||
                orderPosition.addons?.find(
                  (a: any) => a.checkinStatus === 'ok' && a.availableForCheckIn
                ) ||
                (!stewardHadVestBefore && stewardData?.planned) ? (
                  <div>
                    <Button
                      onClick={handleDoCheckIn}
                      variant="success"
                      disabled={
                        (positionsForCheckIn?.length < 1 &&
                          (!stewardData?.planned ||
                            stewardHadVestBefore ||
                            !stewardData?.gotVest)) ||
                        (stewardData?.planned &&
                          !stewardHadVestBefore &&
                          stewardData?.gotVest &&
                          (!stewardData?.vestNumber || stewardData?.vestNumber?.length < 1))
                      }
                      isLoading={isCheckingIn}>
                      Check-In
                    </Button>
                    {stewardData?.planned &&
                    !stewardHadVestBefore &&
                    stewardData?.gotVest &&
                    (!stewardData?.vestNumber || stewardData?.vestNumber?.length < 1) ? (
                      <p className="mt-3">Bitte gib die Nummer der Ordner:innen-Weste ein.</p>
                    ) : null}
                  </div>
                ) : (
                  <p>Es gibt keine Position zum Einchecken (mehr).</p>
                )
              ) : position?.order__status === 'c' ? (
                <p>
                  In dieser Bestellung kann kein Ticket eingecheckt werden, da sie storniert wurde.
                </p>
              ) : position?.order__status === 'e' ? (
                <p>
                  In dieser Bestellung kann kein Ticket eingecheckt werden, da sie abgelaufen ist.
                </p>
              ) : (
                <p>
                  In dieser Bestellung kann kein Ticket eingecheckt werden, da sie noch nicht
                  (vollständig) bezahlt wurde.
                </p>
              )}
              <div className="mt-4">
                <Link
                  to="/"
                  className="font-bold text-blue-900 hover:text-blue-600 transition-colors uppercase">
                  Zurück
                </Link>
              </div>
            </div>
            <div className="rounded shadow p-4 bg-gray-200 mt-4">
              {position?.order ? <Comments code={position.order} /> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Order;
