import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useEffect, useState } from 'react';
import SelectItemList from '../../components/selectItemList';
import usePositionModel from '../../hooks/usePositionModel';
import { useNavigate } from 'react-router-dom';
import useOrderStatusInformation from '../../hooks/useOrderStatusInformation';
import { useLazyListPositionsQuery } from '../../redux/api/checkinListApi';

const Search = () => {
  const authState = useSelector((state: RootState) => state.auth);
  const settingsState = useSelector((state: RootState) => state.settings);
  const [doSearchPositions, { data: positions, isFetching }] = useLazyListPositionsQuery();
  const createPositionModel = usePositionModel();
  const navigate = useNavigate();
  const getOrderStatusInformation = useOrderStatusInformation();

  const [search, setSearch] = useState('');
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    setIsWaiting(true);
    const handler = setTimeout(() => {
      if (search && search.length >= 3) {
        doSearchPositions({
          organizer: authState.organizer,
          event: settingsState.eventSlug,
          listId: settingsState.checkinListId,
          search
        });
      }
      setIsWaiting(false);
    }, 800);

    return () => {
      clearTimeout(handler);
    };
  }, [
    search,
    doSearchPositions,
    authState.organizer,
    settingsState.eventSlug,
    settingsState.checkinListId
  ]);

  useEffect(() => {
    if (
      positions?.results?.length === 1 &&
      search?.length > 10 &&
      positions?.results[0].secret === search
    ) {
      navigate('/order/' + positions.results[0].secret);
    }
  }, [positions, search]);

  const createTitle = (position: any) => {
    return (
      <>
        {position.attendee_name} - {position.attendee_email} - {position.order}
      </>
    );
  };

  const createSubTitle = (position: any) => {
    const positionModel = createPositionModel(position);
    return (
      <div>
        {positionModel.mainPosition.item.name}
        {positionModel.addons?.length > 0 ? (
          <> (+ {positionModel.addons?.map((a: any) => a.item.name).join(', ')})</>
        ) : null}
      </div>
    );
  };

  return (
    <div className="px-12 py-8">
      <div className="max-w-[800px] mx-auto">
        <p className="text-xl font-bold">Bitte scanne ein Ticket oder nutze die Suche.</p>
        <input
          type="text"
          placeholder="Suche..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="mt-6 block w-full px-4 py-3 border-2 border-gray-400 rounded hover:border-gray-500 focus:border-gray-800 outline-none text-xl font-semibold"
        />
        <div className="mt-1">
          {!search || search.length < 3 ? (
            <p>Bitte gib mindestens 3 Zeichen ein, um die Suche zu starten.</p>
          ) : isFetching || isWaiting ? (
            <p>Lädt...</p>
          ) : positions?.results?.length > 0 ? (
            <div className="mt-1">
              <p className="mb-3">
                {positions?.results?.length} Ergebnis{positions?.results?.length !== 1 ? 'se' : ''}
              </p>
              <SelectItemList
                onSelect={(secret) => {
                  navigate('/order/' + secret);
                }}
                items={positions?.results?.map((position: any) => {
                  const positionInformation = getOrderStatusInformation(position);
                  return {
                    id: position.secret,
                    title: createTitle(position),
                    subTitle: createSubTitle(position),
                    batch: {
                      bg: positionInformation.bg,
                      color: positionInformation.text,
                      text: positionInformation.title
                    }
                  };
                })}
              />
            </div>
          ) : (
            <p className="text-red-400 font-semibold text-lg">
              Zu deiner Suche wurde keine Bestellung gefunden.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
