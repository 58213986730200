export default [
  {
    checkInListId: 38,
    products: [91, 96, 188, 190, 192, 193, 210, 201, 238, 212, 204, 205, 191, 211],
    checkInStatus: null,
    text: 'Bekommt ein GÄSTE-Bändsel!'
  },
  {
    checkInListId: 38,
    products: [195],
    variants: [75],
    checkInStatus: null,
    text: 'Bekommt einen BACKSTAGE-1-CarSticker (Zitrone)!'
  },
  {
    checkInListId: 38,
    products: [195],
    variants: [76],
    checkInStatus: null,
    text: 'Bekommt einen BACKSTAGE-2-CarSticker (Brombeere oder rote, runde Grafik)!'
  },
  {
    checkInListId: 38,
    products: [196],
    checkInStatus: null,
    text: 'Bekommt ein BACKSTAGE-1-Bändsel + 4x3 FOOD-MARKEN + 10er-Getränkekarte!'
  },
  {
    checkInListId: 38,
    products: [197],
    checkInStatus: null,
    text: 'Bekommt ein BACKSTAGE-1-Bändsel!'
  },
  {
    checkInListId: 38,
    products: [198],
    checkInStatus: null,
    text: 'Bekommt nur ein BACKSTAGE-1-Bändsel + 4x3 FOOD-MARKEN! Bitte fragen, ob sie mit Auto gekommen sind und dann einen B2-CarSticker ausgeben!'
  },
  {
    checkInListId: 38,
    products: [207, 208, 199, 200],
    checkInStatus: null,
    text: 'Kinder unter 10 bekommen das gelbe PlastikBändsel mit Namen und Telefonnummer. Über 10 das attension#6 Bändsel!'
  },
  {
    checkInListId: 38,
    products: [202, 218],
    checkInStatus: null,
    text: 'Bekommt ein BACKSTAGE-1-Bändsel + 4x3 FOOD-MARKEN und bei Bedarf einen B1-CarSticker! Alles weitere beim Artist-Care!'
  },
  {
    checkInListId: 38,
    products: [203, 219],
    checkInStatus: null,
    text: 'Bekommt ein BACKSTAGE-1-Bändsel und bei Bedarf einen BACKSTAGE-1-CarSticker! Alles weitere beim Artist-Care!'
  },
  {
    checkInListId: 38,
    products: [209],
    checkInStatus: null,
    text: 'Das orangene Tyvek-Bändsel ausgeben!'
  },
  {
    checkInListId: 32,
    products: null,
    checkInStatus: ['already_redeemed'],
    text: 'Daten abfragen, Zeitpunkt des CheckIns abgleichen und mit Gast klären'
  },
  {
    checkInListId: 32,
    products: null,
    checkInStatus: [
      'invalid',
      'canceled',
      'product',
      'rules',
      'revoked',
      'blocked',
      'invalid_time'
    ],
    text: 'kein gültiges Ticket; Verweis auf Ticket:Börse'
  },
  {
    checkInListId: 32,
    products: [91, 92, 93],
    checkInStatus: ['unapproved'],
    text: 'Ticket ungültig - hat nicht in der Ticket:Börse gewonnen'
  },
  {
    checkInListId: 32,
    products: [91, 96, 188, 190, 192, 193, 210, 201, 238, 205],
    checkInStatus: ['ok'],
    text: 'Gastband (LILA)'
  },
  {
    checkInListId: 32,
    products: [92, 216],
    checkInStatus: ['ok'],
    text: 'Kinderband (ROT)'
  },
  {
    checkInListId: 32,
    products: [93, 217],
    checkInStatus: ['ok'],
    text: 'Kleinkinderband (PINK + Plastik) - Bitte Name und Telefonnummer drauf'
  },
  {
    checkInListId: 32,
    products: [188, 190, 216, 217, 192, 200, 201, 215, 205, 206, 207],
    checkInStatus: ['unpaid'],
    text: 'QR-Code mit Tablet (POS-Kasse) scannen und POS-Kassen Handout folgen'
  },
  {
    checkInListId: 32,
    products: [196, 197, 198, 199, 200, 212, 215],
    checkInStatus: [
      'ok',
      'invalid',
      'already_redeemed',
      'canceled',
      'product',
      'rules',
      'revoked',
      'blocked',
      'invalid_time',
      'ambiguous',
      'unapproved'
    ],
    text: 'Zum Schalter C (CrewCheckIn) schicken'
  },
  {
    checkInListId: 32,
    products: [202, 203, 204, 218, 219, 207, 208],
    checkInStatus: [
      'ok',
      'invalid',
      'already_redeemed',
      'canceled',
      'product',
      'rules',
      'revoked',
      'blocked',
      'invalid_time',
      'ambiguous',
      'unapproved'
    ],
    text: 'Zum Schalter A (Artist CheckIn) schicken'
  },
  {
    checkInListId: 32,
    products: [205, 206],
    checkInStatus: [
      'invalid',
      'already_redeemed',
      'canceled',
      'product',
      'rules',
      'revoked',
      'blocked',
      'invalid_time',
      'unapproved'
    ],
    text: 'Zum Schalter A (Artist CheckIn) schicken'
  },
  {
    checkInListId: 32,
    products: [206],
    checkInStatus: ['ok'],
    text: 'B1 Band (GRÜN)'
  },
  {
    checkInListId: 32,
    products: [265, 266],
    checkInStatus: null,
    text: 'Zum Schalter B (Botschaft) schicken'
  }
];
