import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useListEventsQuery } from '../../redux/api/eventApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { clearSettings, setCheckinList, setEvent } from '../../redux/slices/settingsSlice';
import { format } from 'date-fns';
import {
  useLazyListCheckinListsQuery,
  useListCheckinListsQuery
} from '../../redux/api/checkinListApi';
import { removeDeviceSuccess } from '../../redux/slices/authSlice';
import Button from '../../components/inputs/Button';

const Settings: React.FC = () => {
  const authState = useSelector((state: RootState) => state.auth);
  const settingsState = useSelector((state: RootState) => state.settings);
  const dispatch = useDispatch();
  const { data: events } = useListEventsQuery(authState.organizer ?? skipToken);
  const { data: checkinLists } = useListCheckinListsQuery(
    authState.organizer && settingsState.eventSlug
      ? { organizer: authState.organizer, event: settingsState.eventSlug }
      : skipToken
  );
  const [doLoadCheckinListsForSelectedEvent] = useLazyListCheckinListsQuery();
  const [isLocked, setIsLocked] = useState<boolean>(true);

  const handleChangeEvent = async (eventSlug: string) => {
    if (eventSlug) {
      const event = events?.results?.find((event: any) => event.slug === eventSlug);
      if (event) {
        const checkinListsForEventResult = await doLoadCheckinListsForSelectedEvent({
          organizer: authState.organizer,
          event: eventSlug
        });
        if (checkinListsForEventResult.data?.count > 0) {
          dispatch(
            setEvent({ slug: event.slug, name: event.name?.['de-informal'] ?? event.name?.['en'] })
          );
          dispatch(
            setCheckinList({
              id: checkinListsForEventResult.data.results[0].id,
              name: checkinListsForEventResult.data.results[0].name
            })
          );
        } else {
          alert(
            'Für dieses Event gibt es (noch) keine Checkin-Listen. Bitte wähle ein anderes Event aus.'
          );
        }
      }
    }
  };

  const handleChangeCheckInList = (checkinListId: string) => {
    const checkinList = checkinLists?.results?.find(
      (checkinList: any) => checkinList.id?.toString() === checkinListId
    );
    if (checkinList) {
      dispatch(setCheckinList({ id: checkinList.id, name: checkinList.name }));
    }
  };

  const handleLogout = () => {
    if (window.confirm('Möchtest du das Gerät wirklich abmelden?')) {
      dispatch(removeDeviceSuccess());
      dispatch(clearSettings());
    }
  };

  const handleUnlock = () => {
    if (
      prompt('Bitte gebe das Passwort ein:') ===
      (process.env.REACT_APP_UNLOCK_PW ?? 2413000)?.toString()
    ) {
      setIsLocked(false);
    }
  };

  return (
    <div className="px-12 py-8">
      <div className="max-w-[800px] mx-auto">
        <h2 className="text-lg font-bold">Einstellungen</h2>
        <p className="mt-1">Veranstaltung</p>
        <select
          value={settingsState.eventSlug}
          onChange={(e) => handleChangeEvent(e.target.value)}
          disabled={isLocked}
          className="w-full border border-gray-400 rounded px-3 py-2 disabled:bg-gray-200">
          {events?.results
            ?.slice()
            ?.sort(
              (a: any, b: any) => new Date(b.date_from).getTime() - new Date(a.date_from).getTime()
            )
            ?.map((event: any) => (
              <option key={event.slug} value={event.slug}>
                {`${event.name?.['de-informal'] ?? event.name?.['en']} | ${format(new Date(event.date_from), 'dd.MM.yyyy')} - ${format(new Date(event.date_to), 'dd.MM.yyyy')}`}
              </option>
            ))}
        </select>
        <p className="mt-1">Check-In-Liste</p>
        <select
          value={settingsState.checkinListId}
          onChange={(e) => handleChangeCheckInList(e.target.value)}
          disabled={isLocked}
          className="w-full border border-gray-400 rounded px-3 py-2 disabled:bg-gray-200">
          {checkinLists?.results
            ?.slice()
            ?.sort((a: any, b: any) => a.name?.localeCompare(b.name))
            ?.map((checkinList: any) => (
              <option key={checkinList.id} value={checkinList.id}>
                {checkinList.name}
              </option>
            ))}
        </select>
        {isLocked ? (
          <button
            onClick={handleUnlock}
            className="mt-2 text-blue-900 hover:text-blue-600 transition-colors font-semibold">
            Entsperren
          </button>
        ) : null}
        <h2 className="text-lg font-bold mt-8">Anmeldung</h2>
        <table>
          <tbody>
            <tr>
              <th className="text-left pr-3">Url</th>
              <td>{authState.url}</td>
            </tr>
            <tr>
              <th className="text-left pr-3">Veranstalter</th>
              <td>{authState.organizer}</td>
            </tr>
            <tr>
              <th className="text-left pr-3">Name</th>
              <td>{authState.name}</td>
            </tr>
            <tr>
              <th className="text-left pr-3">Gate</th>
              <td>{authState.gate?.name}</td>
            </tr>
          </tbody>
        </table>
        <h2 className="text-lg font-bold mt-8">Gerät abmelden</h2>
        <p>
          Wenn du das Gerät abmeldest, kannst es nicht erneut angemeldet werden und eine Person mit
          entsprechenden Rechten muss in Pretix einen neuen Geräte-Zugang anlegen.
          <br />
          <b>Bitte nutze diese Funktionalität nur, wenn du dir sicher bist, was du tust.</b>
        </p>
        <div className="mt-2">
          <Button onClick={handleLogout}>Gerät abmelden</Button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
