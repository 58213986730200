import React from 'react';

const Steward: React.FC<{
  disabled?: boolean;
  gotVest?: boolean;
  vestNumber?: string;
  onChange?: (arg0: any) => void;
}> = ({ disabled, gotVest, vestNumber, onChange }) => {
  return (
    <div className="rounded shadow p-4 flex flex-col gap-y-2 bg-purple-500 text-white">
      <div className="flex items-center">
        <div className="w-6 h-5 mr-2">
          <input
            type="checkbox"
            className="h-5 w-5"
            checked={gotVest}
            onChange={(e) => onChange && onChange({ gotVest: e.target.checked, vestNumber })}
            disabled={disabled}
          />
        </div>
        <div className="flex items-center">
          <div className="font-semibold">Ordner:innen-Weste</div>
          <input
            disabled={disabled || !gotVest}
            className="ml-4 text-gray-900 text-lg outline-none px-2 rounded"
            type="text"
            onChange={(e) => onChange && onChange({ gotVest, vestNumber: e.target.value })}
            value={vestNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default Steward;
