import React from 'react';
import BarcodeReader from 'react-barcode-reader';

const CodeReader: React.FC<{ onScan?: (data: string) => void }> = ({ onScan }) => {
  const replaceData = (data: any) => {
    data = data?.toString();
    data = data?.replace('|', '{');
    data = data?.replace('≠', '}');
    return data;
  };

  return (
    <div className="CodeReader">
      <BarcodeReader
        //avgTimeByChar={100}
        //timeBeforeScanTest={200}
        onError={(err) => console.log('err', err)}
        onScan={(data) =>
          onScan ? onScan(replaceData(data)) : console.log('data', replaceData(data))
        }
      />
    </div>
  );
};

export default CodeReader;
