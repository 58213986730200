import React from 'react';
import { useGetOrderQuery } from '../../../redux/api/orderApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { skipToken } from '@reduxjs/toolkit/query';
import AddComment from './AddComment';

const Comments: React.FC<{ code: string }> = ({ code }) => {
  const authState = useSelector((state: RootState) => state.auth);
  const settingsState = useSelector((state: RootState) => state.settings);

  const { data: order } = useGetOrderQuery(
    authState.organizer && settingsState.eventSlug && code
      ? { organizer: authState.organizer, event: settingsState.eventSlug, code }
      : skipToken
  );

  return order ? (
    <div>
      <div className="tracking-widest uppercase text-sm">Kommentare</div>
      <pre className="text-sm mt-2 whitespace-pre-wrap break-words">{order.comment}</pre>
      <div className="mt-2">
        <AddComment code={code} />
      </div>
    </div>
  ) : null;
};

export default Comments;
