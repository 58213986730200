import { pretixApi } from './index';

const eventApi = pretixApi.injectEndpoints({
  endpoints: (builder) => ({
    listEvents: builder.query<any, string>({
      query: (organizer) => `/api/v1/organizers/${organizer}/events/`
    })
  })
});

export const { useListEventsQuery } = eventApi;
