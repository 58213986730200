import React from 'react';

const Supporter: React.FC<{ data: any }> = ({ data }) => {
  return (
    <div className="rounded bg-cyan-300 p-4">
      <div>
        <span className="font-bold">Arbeitszeitraum: </span>
        {data.period}
      </div>
      {data.shifts?.length > 0 ? (
        <div className="mt-2">
          <div className="font-bold">Schichten:</div>
          {data.shifts?.map((shift: string, i: number) => <div key={i}>{shift}</div>)}
        </div>
      ) : null}
    </div>
  );
};

export default Supporter;
