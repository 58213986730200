import { useState } from 'react';
import { useDispatch } from 'react-redux';
import packageJson from '../../package.json';
import { initializationSuccess } from '../redux/slices/authSlice';

function useInitialization() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const doInitialization = async (url: string, token: string) => {
    try {
      setIsLoading(true);
      const response = await fetch(`${url}/api/v1/device/initialize`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: token,
          hardware_brand: 'REACT',
          hardware_model: navigator.platform,
          os_name: navigator.vendor?.length > 0 ? navigator.vendor : 'Unknown',
          os_version: navigator.platform,
          software_brand: 'Kuko Pretix-Check-In',
          software_version: packageJson.version
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      dispatch(
        initializationSuccess({
          url: url,
          apiToken: result.api_token,
          organizer: result.organizer,
          uniqueSerial: result.unique_serial,
          name: result.name,
          gate: result.gate
        })
      );
      setIsLoading(false);
    } catch (e) {
      console.error('Error during initialization', e);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    doInitialization
  };
}

export default useInitialization;
