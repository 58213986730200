import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import authReducer from './slices/authSlice';
import settingsReducer from './slices/settingsSlice';
import { configureStore } from '@reduxjs/toolkit/react';
import { pretixApi } from './api';
import { combineReducers } from '@reduxjs/toolkit';

const persistConfig = {
  key: process.env.REACT_APP_KEY ?? 'kuko-pretix-checkin',
  storage,
  whitelist: ['auth', 'settings']
};

const reducer: any = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  [pretixApi.reducerPath]: pretixApi.reducer
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store: any = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) => getDefaultMiddleware().concat([pretixApi.middleware])
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
