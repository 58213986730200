import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useListItemsQuery } from '../redux/api/itemsApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { useListCheckinListsQuery } from '../redux/api/checkinListApi';
import { format } from 'date-fns';
import CheckInTextConfig from '../config/CheckInTextConfig';

function usePositionModel() {
  const authState = useSelector((state: RootState) => state.auth);
  const settingsState = useSelector((state: RootState) => state.settings);
  const { data: items } = useListItemsQuery(
    authState.organizer && settingsState.eventSlug
      ? { organizer: authState.organizer, event: settingsState.eventSlug }
      : skipToken
  );
  const { data: checkinLists } = useListCheckinListsQuery(
    authState.organizer && settingsState.eventSlug
      ? { organizer: authState.organizer, event: settingsState.eventSlug }
      : skipToken
  );

  const getItemName = (itemId: number, variation?: number) => {
    const item = items?.results?.find((item: any) => item.id === itemId);
    if (variation) {
      const variationItem = item?.variations?.find((v: any) => v.id === variation);
      return variationItem?.value?.['de-informal'] ?? variationItem?.value?.['en'];
    } else {
      return item?.name?.['de-informal'] ?? item?.name?.['en'];
    }
  };

  const getItemCheckInText = (itemId: number, position: any) => {
    let text = '';
    if (settingsState.checkinListName?.includes('Crew')) {
      const item = items?.results?.find((item: any) => item.id === itemId);
      text = item?.checkin_text;
    }
    CheckInTextConfig.filter((c) => c.checkInListId === settingsState.checkinListId)?.forEach(
      (config) => {
        if (config.products === null || config.products.includes(itemId)) {
          if (
            config.variants === undefined ||
            config.variants === null ||
            config.variants.includes(position.variation)
          ) {
            if (
              config.checkInStatus === null ||
              config.checkInStatus.includes(position.checkin_status)
            ) {
              text = config.text;
            }
          }
        }
      }
    );
    return text;
  };

  const getItemAvailableForCheckIn = (itemId: number) => {
    const item = items?.results?.find((item: any) => item.id === itemId);
    return item?.admission;
  };

  const createCheckedInText = (position: any) => {
    const checkIn = position.checkins?.find((c: any) => !c.auto_checked_in);
    const checkInTime = checkIn?.datetime;
    const checkInList = checkinLists?.results?.find((c: any) => c.id === checkIn?.list);
    return `Das Ticket wurde bereits eingecheckt (${format(new Date(checkInTime), 'dd.MM.yy HH:mm')}, ${checkInList?.name})`;
  };

  const getCheckInStatusText = (position: any) => {
    switch (position.checkin_status) {
      case 'unpaid':
        return 'Das Ticket ist noch nicht bezahlt.';
      case 'blocked':
        return 'Das Ticket wurde blockiert.';
      case 'invalid_time':
        return 'Das Ticket darf aktuell nicht eingecheckt werden.';
      case 'canceled':
        return 'Das Ticket wurde storniert.';
      case 'already_redeemed':
        return createCheckedInText(position);
      case 'product':
        return 'Dieses Produkt darf hier nicht eingecheckt werden.';
      case 'revoked':
        return 'Das Ticket wurde widerrufen.';
      case 'unapproved':
        return 'Das Ticket wurde noch nicht freigegeben.';
      default:
        return null;
    }
  };

  return (position: any) => {
    return {
      mainPosition: {
        id: position.id,
        item: {
          id: position.item,
          name: getItemName(position.item, position.variation),
          checkInText: getItemCheckInText(position.item, position)
        },
        attendeeName: position.attendee_name,
        availableForCheckIn: getItemAvailableForCheckIn(position.item),
        checkedIn: position.checkins?.length > 0,
        checkinStatus: position.checkin_status,
        checkinStatusText: getCheckInStatusText(position),
        positionId: position.positionid
      },
      addons: position.addons
        ?.filter((a: any) => getItemAvailableForCheckIn(a.item))
        ?.map((a: any) => ({
          id: a.id,
          item: {
            id: a.item,
            name: getItemName(a.item, a.variation),
            checkInText: getItemCheckInText(a.item, a)
          },
          availableForCheckIn: true, //getItemAvailableForCheckIn(a.item),
          checkedIn: a.checkins?.length > 0,
          checkinStatus: a.checkin_status,
          checkinStatusText: getCheckInStatusText(a),
          positionId: position.positionid
        }))
    };
  };
}

export default usePositionModel;
