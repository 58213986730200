import { useListEventsQuery } from '../../redux/api/eventApi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { skipToken } from '@reduxjs/toolkit/query';
import SelectItemList from '../../components/selectItemList';
import React from 'react';
import { setEvent } from '../../redux/slices/settingsSlice';
import { format } from 'date-fns';

const SelectEvent: React.FC = () => {
  const authState = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const { data } = useListEventsQuery(authState.organizer ?? skipToken);

  const handleSelect = (id: string) => {
    const event = data?.results?.find((event: any) => event.slug === id);
    if (event) {
      dispatch(
        setEvent({ slug: event.slug, name: event.name?.['de-informal'] ?? event.name?.['en'] })
      );
    }
  };

  return (
    <div className="px-12 py-8">
      <div className="max-w-[800px] mx-auto">
        <h2 className="text-lg font-bold">Veranstaltung auswählen</h2>
        <p>Bitte wähle die Veranstaltung aus, für welche du Tickets einchecken möchtest.</p>
        <div className="mt-4">
          <SelectItemList
            items={data?.results
              ?.slice()
              ?.sort(
                (a: any, b: any) =>
                  new Date(b.date_from).getTime() - new Date(a.date_from).getTime()
              )
              ?.map((event: any) => ({
                id: event.slug,
                title: event.name?.['de-informal'] ?? event.name?.['en'],
                subTitle: `${format(new Date(event.date_from), 'dd.MM.yyyy')} - ${format(new Date(event.date_to), 'dd.MM.yyyy')}`
              }))}
            onSelect={handleSelect}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectEvent;
