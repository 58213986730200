import packageJson from '../../../package.json';

const Version = () => {
  return (
    <div className="fixed z-20 -rotate-90 origin-top-left left-0 -bottom-2 text-xs text-gray-400">
      v{packageJson.version}
    </div>
  );
};

export default Version;
