import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  authenticated: false,
  url: null,
  apiToken: null,
  organizer: null,
  uniqueSerial: null,
  name: null,
  gate: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initializationSuccess(state, action) {
      state.authenticated = true;
      state.url = action.payload.url;
      state.apiToken = action.payload.apiToken;
      state.organizer = action.payload.organizer;
      state.uniqueSerial = action.payload.uniqueSerial;
      state.name = action.payload.name;
      state.gate = action.payload.gate;
    },
    removeDeviceSuccess(state) {
      Object.assign(state, initialState);
    }
  }
});

export const { initializationSuccess, removeDeviceSuccess } = authSlice.actions;

export default authSlice.reducer;
