import React, { ReactNode } from 'react';
import classNames from 'classnames';

const SelectItemList: React.FC<{
  items?: {
    id: any;
    title: string | ReactNode;
    subTitle?: string | ReactNode;
    batch?: { bg: string; color: string; text?: string };
  }[];
  onSelect: (id: any) => void;
}> = ({ items, onSelect }) => {
  return (
    <ul className="flex flex-col gap-y-2">
      {items?.map((item) => (
        <li
          key={item.id}
          onClick={() => onSelect(item.id)}
          className="border border-gray-300 hover:border-cyan-900 rounded cursor-pointer hover:bg-gray-50 transition-colors text-lg flex bg-">
          <div className="px-2 py-1">
            {item.title}
            <div className="text-sm text-gray-500 bg-">{item.subTitle}</div>
          </div>
          {item.batch ? (
            <div
              className={classNames(
                'ml-auto min-w-64 px-2 py-1 text-md flex items-center justify-center uppercase text-sm',
                item.batch.bg,
                item.batch.color
              )}>
              {item.batch.text}
            </div>
          ) : null}
        </li>
      ))}
    </ul>
  );
};

export default SelectItemList;
