import React from 'react';
import Crew from './Crew';
import Supporter from './Supporter';

const KukoData: React.FC<{ kukoData: any }> = ({ kukoData }) => {
  switch (kukoData.type) {
    case 'crew':
      return <Crew data={kukoData.data} />;
    case 'supporter':
      return <Supporter data={kukoData.data} />;
    default:
      return null;
  }
};

export default KukoData;
