import { ReactElement, useState } from 'react';
import classNames from 'classnames';

const Tabs = ({ config }: { config: { key: string; content: ReactElement; title: string }[] }) => {
  const [currentTab, setCurrentTab] = useState(config[0]?.key);

  return (
    <div className="bg-gray-50 py-2 px-4 rounded shadow">
      <div className="flex gap-x-2">
        {config.map((c) => (
          <button
            key={c.key}
            onClick={() => setCurrentTab(c.key)}
            className={classNames(
              'px-2 py-1 border-b-4 transition-colors',
              currentTab === c.key ? 'border-b-cyan-900' : 'border-gray-300'
            )}>
            {c.title}
          </button>
        ))}
      </div>
      <div className="p-2">{config.find((c) => c.key === currentTab)?.content}</div>
    </div>
  );
};

export default Tabs;
