import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useListQuestionsQuery } from '../../../redux/api/questionApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { format } from 'date-fns';

const Answers: React.FC<{ position: any }> = ({ position }) => {
  const authState = useSelector((state: RootState) => state.auth);
  const settingsState = useSelector((state: RootState) => state.settings);
  const { data: questions } = useListQuestionsQuery(
    authState.organizer && settingsState.eventSlug
      ? { organizer: authState.organizer, event: settingsState.eventSlug }
      : skipToken
  );
  return (
    <div>
      {position.answers?.map((answer: any) => {
        const question = questions?.results?.find((q: any) => q.id === answer.question);
        if (
          question?.show_during_checkin ||
          ['Name of the act', 'Function'].includes(question?.question?.['en'])
        ) {
          switch (question?.type) {
            case 'D':
              return (
                <div key={answer.question} className="flex mt-1">
                  <div className="tracking-wider uppercase text-sm">
                    {question?.question?.['de-informal'] ?? question?.question?.['en']}
                  </div>
                  <div className="text-sm ml-1 font-semibold">
                    {format(new Date(answer.answer), 'dd.MM.yyyy')}
                  </div>
                </div>
              );
            case 'S':
              return (
                <div key={answer.question} className="flex mt-1">
                  <div className="tracking-wider uppercase text-sm">
                    {question?.question?.['de-informal'] ?? question?.question?.['en']}
                  </div>
                  <div className="text-sm ml-1 font-semibold">{answer.answer}</div>
                </div>
              );
          }
        }
      })}
    </div>
  );
};

export default Answers;
