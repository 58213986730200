import { FormEvent, useState } from 'react';
import useInitialization from '../../../hooks/useInitialization';
import TextInput from '../../../components/inputs/TextInput';
import Button from '../../../components/inputs/Button';

const Manual = () => {
  const { isLoading, doInitialization } = useInitialization();

  const [url, setUrl] = useState('');
  const [token, setToken] = useState('');

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (url && token) {
      await doInitialization(url, token);
    } else {
      alert('Bitte fülle alle Felder aus.');
    }
  };

  return (
    <div>
      <p>
        Bitte gib die URL und den Token ein, die du von Pretix erhalten hast, um die Initialisierung
        abzuschließen.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="max-w-[400px]">
          <TextInput
            onChange={(e) => setUrl(e.target.value)}
            placeholder="URL"
            value={url}
            required={true}
          />
          <TextInput
            onChange={(e) => setToken(e.target.value)}
            placeholder="Token"
            value={token}
            required={true}
          />
        </div>
        <Button type="submit">Starte Initialisierung</Button>
        {isLoading ? <div>Initialisiere...</div> : null}
      </form>
    </div>
  );
};

export default Manual;
