import QrCode from './forms/QrCode';
import Manual from './forms/Manual';
import Tabs from '../../components/tabs';

const availableForms = [
  {
    key: 'qrCode',
    title: 'QR-Code',
    content: <QrCode />
  },
  {
    key: 'manual',
    title: 'Manuell',
    content: <Manual />
  }
];

const Initialize = () => {
  return (
    <div className="px-12 py-8">
      <div className="max-w-[800px] mx-auto">
        <h2 className="text-lg font-bold">Verbindung zu Pretix herstellen</h2>
        <p>
          Um den Check-In zu starten, musst du dein Gerät mit Pretix verbinden.
          <br />
          Dafür muss im Pretix-Backend ein neues Gerät angelegt werden.
          <br />
          Dann kannst du entweder den dort angezeigten QR-Code scannen oder die Verbindungsdaten
          manuell eingeben.
        </p>
        <div className="mt-8">
          <Tabs config={availableForms} />
        </div>
      </div>
    </div>
  );
};

export default Initialize;
