import React from 'react';

const Crew: React.FC<{ data: any }> = ({ data }) => {
  return data ? (
    <>
      <div className="rounded bg-cyan-300 p-4">
        <div className="tracking-widest uppercase text-sm">Crews</div>
        {data.crews?.map((crew: any, i: number) => <div key={i}>{crew.name}</div>)}
      </div>
      {data.crewCheckInInfos?.map((crewCheckInInfo: any, i: number) => (
        <div key={i} className="rounded bg-amber-300 p-4">
          <div className="tracking-widest uppercase text-sm">
            Crew-Hinweis für {crewCheckInInfo.crewName}
          </div>
          <div>{crewCheckInInfo.value}</div>
        </div>
      ))}
      {data.projectCrewPersonCheckInInfos?.map((projectCrewPersonCheckInInfo: any, i: number) => (
        <div key={i} className="rounded bg-amber-300 p-4">
          <div className="tracking-widest uppercase text-sm">
            Persönlicher Hinweis über {projectCrewPersonCheckInInfo.crewName}
          </div>
          <div>{projectCrewPersonCheckInInfo.checkInInfo}</div>
        </div>
      ))}
    </>
  ) : null;
};

export default Crew;
