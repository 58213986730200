import { pretixApi } from './index';

const itemsApi = pretixApi.injectEndpoints({
  endpoints: (builder) => ({
    listItems: builder.query<any, { organizer: string; event: string }>({
      query: ({ organizer, event }) => `/api/v1/organizers/${organizer}/events/${event}/items/`,
      providesTags: ['Items']
    })
  })
});

export const { useListItemsQuery } = itemsApi;
