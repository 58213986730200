import React, { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useListCheckinListsQuery } from '../../redux/api/checkinListApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { setCheckinList } from '../../redux/slices/settingsSlice';
import SelectItemList from '../../components/selectItemList';

const SelectCheckinList = () => {
  const authState = useSelector((state: RootState) => state.auth);
  const settingsState = useSelector((state: RootState) => state.settings);
  const dispatch = useDispatch();
  const { data } = useListCheckinListsQuery(
    authState.organizer && settingsState.eventSlug
      ? { organizer: authState.organizer, event: settingsState.eventSlug }
      : skipToken
  );

  const handleSelect = (id: string) => {
    const checkinList = data?.results?.find((checkinList: any) => checkinList.id === id);
    if (checkinList) {
      dispatch(setCheckinList({ id: checkinList.id, name: checkinList.name }));
    }
  };

  return (
    <div className="px-12 py-8">
      <div className="max-w-[800px] mx-auto">
        <h2 className="text-lg font-bold">Check-In-Liste auswählen</h2>
        <p>Bitte wähle die Check-In-Liste aus, auf welcher du Tickets einchecken möchtest.</p>
        <div className="mt-4">
          <SelectItemList
            onSelect={handleSelect}
            items={data?.results
              ?.slice()
              ?.sort((a: any, b: any) => a.name?.localeCompare(b.name))
              ?.map((checkinList: any) => ({
                id: checkinList.id,
                title: checkinList.name
              }))}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectCheckinList;
