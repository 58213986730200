import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChampagneGlasses, faClipboardList, faGear } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Link } from 'react-router-dom';

const ShowSettings: React.FC = () => {
  const authState = useSelector((state: RootState) => state.auth);
  const settingsState = useSelector((state: RootState) => state.settings);
  return authState.authenticated && settingsState.eventSlug && settingsState.checkinListId ? (
    <div className="rounded border border-gray-400 px-3 py-2 text-sm text-gray-700 flex items-center">
      <table>
        <tbody>
          <tr>
            <td>
              <FontAwesomeIcon icon={faChampagneGlasses} className="mr-2" />
            </td>
            <td>{settingsState.eventName}</td>
          </tr>
          <tr>
            <td>
              <FontAwesomeIcon icon={faClipboardList} className="mr-2" />
            </td>
            <td>{settingsState.checkinListName}</td>
          </tr>
        </tbody>
      </table>
      <Link
        to="/settings"
        className="text-4xl ml-4 text-blue-900 hover:text-blue-600 transition-colors">
        <FontAwesomeIcon icon={faGear} />
      </Link>
    </div>
  ) : null;
};

export default ShowSettings;
