import { pretixApi } from './index';

const checkinListApi = pretixApi.injectEndpoints({
  endpoints: (builder) => ({
    listCheckinLists: builder.query<any, { organizer: string; event: string }>({
      query: ({ organizer, event }) =>
        `/api/v1/organizers/${organizer}/events/${event}/checkinlists/`
    }),
    listPositions: builder.query<
      any,
      { organizer: string; event: string; listId: string; search?: string }
    >({
      query: ({ organizer, event, listId, search }) =>
        `/api/v1/organizers/${organizer}/events/${event}/checkinlists/${listId}/positions_kuko/${search ? `?search=${search}` : ''}`,
      providesTags: ['Positions']
    }),
    redeem: builder.mutation<
      any,
      { organizer: string; event: string; listId: string; positionId: number }
    >({
      query: ({ organizer, event, listId, positionId }) => ({
        url: `/api/v1/organizers/${organizer}/events/${event}/checkinlists/${listId}/positions/${positionId}/redeem/`,
        method: 'POST',
        body: {
          questions_supported: false
        }
      }),
      invalidatesTags: ['Positions']
    })
  })
});

export const {
  useLazyListCheckinListsQuery,
  useListCheckinListsQuery,
  useLazyListPositionsQuery,
  useListPositionsQuery,
  useRedeemMutation
} = checkinListApi;
