import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useGetOrderQuery } from '../../../redux/api/orderApi';
import { skipToken } from '@reduxjs/toolkit/query';

const InvoiceAddress: React.FC<{ code: string }> = ({ code }) => {
  const authState = useSelector((state: RootState) => state.auth);
  const settingsState = useSelector((state: RootState) => state.settings);

  const { data: order } = useGetOrderQuery(
    authState.organizer && settingsState.eventSlug && code
      ? { organizer: authState.organizer, event: settingsState.eventSlug, code }
      : skipToken
  );

  return order && order.invoice_address ? (
    <div className="mt-2">
      <div className="tracking-widest uppercase text-sm">Rechnungsadresse</div>
      <div className="text-sm">{order.invoice_address?.name}</div>
      <div className="text-sm">{order.invoice_address?.street}</div>
      <div className="text-sm">
        {order.invoice_address?.zipcode} {order.invoice_address?.city}
      </div>
      <div className="text-sm">{order.invoice_address?.country}</div>
    </div>
  ) : null;
};

export default InvoiceAddress;
