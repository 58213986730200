import React from 'react';
import Initialize from './pages/Initialize';
import { useSelector } from 'react-redux';
import SelectEvent from './pages/SelectEvent';
import { RootState } from './redux/store';
import SelectCheckinList from './pages/SelectCheckinList';
import Header from './components/header';
import Search from './pages/Search';
import { Route, Routes } from 'react-router-dom';
import Order from './pages/Order';
import OrderLoader from './components/OrderLoader';
import Settings from './pages/Settings';
import Version from './components/version';

function App() {
  const authState = useSelector((state: RootState) => state.auth);
  const settingsState = useSelector((state: RootState) => state.settings);

  return (
    <div className="App">
      <Version />
      <Header />
      {!authState.authenticated ? (
        <Initialize />
      ) : !settingsState.eventSlug ? (
        <SelectEvent />
      ) : !settingsState.checkinListId ? (
        <SelectCheckinList />
      ) : (
        <>
          <OrderLoader />
          <Routes>
            <Route index element={<Search />} />
            <Route path="/order/:secret" element={<Order />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </>
      )}
    </div>
  );
}

export default App;
