import usePositionModel from './usePositionModel';

function useOrderStatusInformation() {
  const createPositionModel = usePositionModel();

  return (position: any) => {
    const positionModel = createPositionModel(position);
    const defaultInformation = {
      bg: 'bg-gray-50',
      text: 'text-black',
      title: 'unbekannt'
    };
    switch (position.order__status) {
      case 'n':
        if (position.ticketbourse_is_paid) {
          if (
            (positionModel.mainPosition.checkinStatus === 'ok' ||
              !positionModel.mainPosition.availableForCheckIn) &&
            positionModel.addons?.every(
              (a: any) => a.checkinStatus === 'ok' || !a.availableForCheckIn
            )
          ) {
            return {
              bg: 'bg-green-500',
              text: 'text-gray-50',
              title: 'gültig'
            };
          } else if (
            (positionModel.mainPosition.checkedIn ||
              !positionModel.mainPosition.availableForCheckIn) &&
            positionModel.addons?.every((a: any) => a.checkedIn || !a.availableForCheckIn)
          ) {
            return {
              bg: 'bg-fuchsia-700',
              text: 'text-gray-50',
              title: 'bereits eingelöst'
            };
          } else {
            return {
              bg: 'bg-amber-400',
              text: 'text-black',
              title: 'wartet auf Bezahlung'
            };
          }
        } else {
          return {
            bg: 'bg-amber-400',
            text: 'text-black',
            title: 'wartet auf Bezahlung'
          };
        }
      case 'p':
        if (
          (positionModel.mainPosition.checkinStatus === 'ok' ||
            !positionModel.mainPosition.availableForCheckIn) &&
          positionModel.addons?.every(
            (a: any) => ['canceled', 'ok'].includes(a.checkinStatus) || !a.availableForCheckIn
          )
        ) {
          return {
            bg: 'bg-green-500',
            text: 'text-gray-50',
            title: 'gültig'
          };
        } else if (
          (positionModel.mainPosition.checkedIn ||
            !positionModel.mainPosition.availableForCheckIn) &&
          positionModel.addons?.every(
            (a: any) =>
              a.checkedIn || ['canceled'].includes(a.checkinStatus) || !a.availableForCheckIn
          )
        ) {
          return {
            bg: 'bg-fuchsia-700',
            text: 'text-gray-50',
            title: 'bereits eingelöst'
          };
        } else if (positionModel.mainPosition.checkinStatus === 'canceled') {
          return {
            bg: 'bg-red-600',
            text: 'text-gray-50',
            title: 'storniert'
          };
        } else if (positionModel.mainPosition.checkinStatus === 'product') {
          return {
            bg: 'bg-red-400',
            text: 'text-gray-50',
            title: 'hier nicht erlaubt'
          };
        } else {
          return {
            bg: 'bg-yellow-400',
            text: 'text-black',
            title: 'teilweise eingelöst'
          };
        }
      case 'e':
        return {
          bg: 'bg-red-400',
          text: 'text-black',
          title: 'abgelaufen'
        };
      case 'c':
        return {
          bg: 'bg-red-600',
          text: 'text-gray-50',
          title: 'storniert'
        };
      default:
        return defaultInformation;
    }
  };
}

export default useOrderStatusInformation;
